<template>
    <section>
      <div class="w-full bg-white rounded-md mt-2 p-4">
        <div class="w-full flex gap-2 items-center">
          <i class="pi pi-list"></i>
          <p class="text-lg font-bold">Listado de pacientes</p>
          <Button @click="$router.push({ name: 'pharmasan.ventas.configuracion.pacientes.crear' })" class="ml-auto rounded-md" label="Crear Paciente" />
        </div>
        <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-6 gap-2 my-2">
          <!-- filtro por codigos de articulo -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="cod_bod">No. identificación</label>
            <InputText class="w-full" id="cod_bod" @keyup.enter="listarPac" v-model="filtros.U_ACS_NmrIdentf" />
          </div>
          <!-- filtro por descripcion -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="descripcion">Nombre</label>
            <InputText class="w-full" id="descripcion" @keyup.enter="listarPac" v-model="filtros.U_ACS_PrimerNmb"/>
          </div>
          <!-- filtro por bodega -->
          <div class="flex gap-2">
            <Button @click="listarPac" class="mt-5 mb-1.5 rounded-md" label="Buscar" />
            <Button @click="limpiarFiltros" class="mt-5 mb-1.5 rounded-md p-button-outlined p-button-secondary" label="Limpiar" />
          </div>
          <Button @click="listarPacSync" class="p-button-outlined p-button-success mt-5 mb-1.5 mr-2 rounded-md ml-auto" label="Historial Sync" style="grid-column-start: 5;" />
          <Button @click="listarPacSyncPend" class="p-button-outlined mt-5 mb-1.5 rounded-md ml-auto" label="Syncronizar Sap" style="grid-column-start: 6;" />
        </div>
        <div class="w-full my-2">
          <DataTable class="p-datatable-sm text-xs" :value="pacientes.rows" :rowHover="true" responsiveLayout="scroll">
            <Column header="Doc" style="min-width:130px" >
              <template #body="{data}">
                <section @click="EditPac(data.Id)">
                  <span class="underline text-blue-600 cursor-pointer">{{ data.U_ACS_TpoIdentf }}. {{ data.U_ACS_NmrIdentf }}</span>
                </section>
              </template>
            </Column>
            <Column field="U_ACS_NmbPct" headerStyle="width:150%; " style="min-width:180px" header="Nombre"></Column>
            <Column field="U_ACS_Regimen" header="Régimen"></Column>
            <Column field="U_ACS_NmbEntidad" header="Entidad"></Column>
            <Column header="Fecha Nac" style="min-width:90px">
              <template #body="{data}">
                <div class="text-center pt-3">
                  {{ helper.formatDate(data.U_ACS_FchNacim, 'YYYY-MM-DD') }}
                </div>
              </template>
            </Column>
            <Column header="Edad">
              <template #body="{data}">
                <div class="text-center pt-3">
                  {{ helper.formatDate(data.U_ACS_FchNacim, 'YYYY-MM-DD') }}
                </div>
              </template>
            </Column>
            <Column field="U_ACS_CdgPct" header="U_ACS_CdgPct"></Column>
            <Column field="U_ACS_Sexo" header="Sexo"></Column>
            <Column field="U_ACS_TpoUsuario" header="Tipo usuario"></Column>
            <Column field="U_ACS_Nivel" header="Nivel"></Column>
            <Column field="U_ACS_Exonerado" header="Exonerado"></Column>
            <Column field="U_PHR_CodDane" header="CodDane"></Column>
            <Column field="U_ACS_ClienInter" header="Cliente"></Column>
            <Column field="U_ACS_Estado" header="Estado"></Column>
            <Column field="U_ACS_LgrNacmt" header="Lugar Nacimiento"></Column>
            <Column field="U_ACS_EstCivil" header="Estado Civil"></Column>
            <Column field="U_ACS_Rh" header="RH"></Column>
            <Column field="U_ACS_NmrHijos" header="Hijos"></Column>
            <Column field="U_ACS_Zona" header="Zona"></Column>
            <Column header="Estado">
              <template #body="{data}">
                <div class="text-center">
                  <Badge v-if="data.U_ACS_Activo == 'N'" :value="data.U_ACS_Activo == 'N' ? 'Inactivo': ''" severity="danger"></Badge>
                </div>
              </template></Column>

            <Column header="Acción">
              <template #body="{data}">
                <div class="p-2 rounded-md text-center" >
                  <Button
                      @click='DeletePac(data.Id)'
                      severity="danger"
                      v-tooltip="'Eliminar'"
                      class='p-button-rounded p-button-icon-only mx-2'
                  >
                    <i class="pi pi-trash text-white"></i>
                  </Button>
                </div>
              </template>
            </Column>
          </DataTable>
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(pacientes.count)"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)"
          />
        </div>
      </div>
      <!-- inicio modal sincronizar pendientes -->
      <Dialog headerClass="text-xs" header="Sincronizar Pacientes" :closable="false" v-model:visible="modalSyncPacPend" :style="{width: '70vw'}" :modal="true">
        <div class="flex gap-2 mt-2 text-xs">
          <div>
            <DataTable class="p-datatable-sm text-xs" :value="pacientesSyncPend.rows" :rowHover="true" responsiveLayout="scroll">
              <template #empty> Sin pacientes para sincronizar. </template>
              <Column header="Doc" style="min-width:130px" >
                <template #body="{data}">
                    <span @click="EditPac(data.Id)" class="underline text-blue-600 cursor-pointer">{{ data.U_ACS_TpoIdentf }}. {{ data.U_ACS_NmrIdentf }}</span>
                </template>
              </Column>
              <Column field="U_ACS_NmbPct" headerStyle="width:150%; " style="min-width:180px" header="Nombre">
              </Column>
              <Column field="U_ACS_Regimen" header="Régimen"></Column>
              <Column field="U_ACS_NmbEntidad" header="Entidad"></Column>
              <Column header="Fecha Nac" style="min-width:90px">
                <template #body="{data}">
                  <div class="text-center pt-3">
                    {{ helper.formatDate(data.U_ACS_FchNacim, 'YYYY-MM-DD') }}
                  </div>
                </template>
              </Column>
              <Column field="U_ACS_Sexo" header="Sexo"></Column>
              <Column field="U_ACS_TpoUsuario" header="Tipo usuario"></Column>
              <Column field="U_ACS_Nivel" header="Nivel"></Column>
              <Column field="U_PHR_CodDane" header="CodDane"></Column>
              <Column field="U_ACS_ClienInter" header="Cliente"></Column>
              <Column field="U_ACS_Estado" header="Estado"></Column>
              <Column header="Respuesta" style="min-width:130px" >
                <template #body="{data}">
                    <span v-if="data.Response" v-tippy="{ content: data.Response }" class="underline text-blue-600 cursor-pointer">Error</span>
                </template>
              </Column>
            </DataTable>
            <Paginator v-model:first="offsetSyncPend"
              :rows="limitSyncPend"
              :totalRecords="parseInt(pacientesSyncPend.count)"
              :rowsPerPageOptions="[2,10,20,30,100]"
              @page="onPageSyncPend($event)"
            />
          </div>
        </div>
        <template #footer>
          <Button class="p-button" @click="SincronizarPac" label="Enviar a Sincronizar" />
          <Button class="p-button-outlined p-button-secondary" @click="modalSyncPacPend = false" label="Cerrar" />
        </template>
      </Dialog>
      <!-- fin modal sincronizar pendientes-->
      <!-- inicio modal sincronizar -->
      <Dialog headerClass="text-xs" header="Sincronizar Pacientes" :closable="false" v-model:visible="modalSyncPac" :style="{width: '70vw'}" :modal="true">
        <div class="flex gap-2 mt-2 text-xs">
          <div>
            <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-6 gap-2 my-2">
              <!-- filtro por codigos de articulo -->
              <div class="w-full">
                <label class="text-xs text-gray-600" for="n_ident">No. identificación</label>
                <InputText class="w-full" id="n_ident" v-model="filtrosSync.U_ACS_NmrIdentf" />
              </div>
              <!-- filtro por descripcion -->
              <div class="w-full">
                <label class="text-xs text-gray-600" for="name_zync">Nombre</label>
                <InputText class="w-full" id="name_zync" v-model="filtrosSync.U_ACS_PrimerNmb"/>
              </div>
              <!-- filtro por bodega -->
              <div class="flex gap-2">
                <Button @click="listarPacSync" class="mt-5 mb-1.5 rounded-md" label="Buscar" />
              </div>
            </div>
            <DataTable class="p-datatable-sm text-xs" :value="pacientesSync.rows" :rowHover="true" responsiveLayout="scroll">
              <template #empty> Sin pacientes para sincronizar. </template>
              <Column header="Doc" style="min-width:130px" >
                <template #body="{data}">
                    <span @click="EditPac(data.Id)" class="underline text-blue-600 cursor-pointer">{{ data.U_ACS_TpoIdentf }}. {{ data.U_ACS_NmrIdentf }}</span>
                </template>
              </Column>
              <Column field="U_ACS_NmbPct" headerStyle="width:150px; " style="min-width:180px" header="Nombre"></Column>
              <Column field="U_ACS_CdgPct" headerStyle="width:80px; " style="min-width:80px" header="CdgPct"></Column>
              <Column field="Response" headerStyle="width:150%; " style="min-width:180px" header="Response"></Column>
              <Column field="Estado" header="Estado"></Column>
            </DataTable>
            <Paginator v-model:first="offsetSync"
              :rows="limitSync"
              :totalRecords="parseInt(pacientesSync.count)"
              :rowsPerPageOptions="[2,10,20,30,100]"
              @page="onPageSync($event)"
            />
          </div>
        </div>
        <template #footer>
          <Button class="p-button-outlined p-button-secondary" @click="modalSyncPac = false" label="Cerrar" />
        </template>
      </Dialog>
      <!-- fin modal sincronizar -->
      <Toast />
    </section>
</template>
<script setup>
  // import dayjs from 'dayjs'
  import { useRouter } from 'vue-router'
  import { useToast } from 'primevue/usetoast'
  import Swal from 'sweetalert2'
  import PacienteService from '../../../../../services/pacientes.service'
  import { onMounted, ref, computed } from 'vue'
  import { helper } from '@/utils/helper'
  // name: 'ListadoOrdenesVenta'
  // Servicios
  const router = useRouter()
  const toast = useToast()
    const PacientesService = ref(new PacienteService())
    // Referencias
    const modalSyncPacPend = ref(false)
    const modalSyncPac = ref(false)
    const pacientes = ref([])
    const pacientesSyncPend = ref([])
    const pacientesSync = ref([])
    const limit = ref(10)
    const offset = ref(0)
    const limitSync = ref(10)
    const offsetSync = ref(0)
    const limitSyncPend = ref(10)
    const offsetSyncPend = ref(0)
    const filtros = ref({
      U_ACS_NmrIdentf: null,
      U_ACS_PrimerNmb: null
    })
    const filtrosSync = ref({
      U_ACS_NmrIdentf: null,
      U_ACS_PrimerNmb: null
    })
    const params = computed(() => {
      return {
        limit: limit.value,
        offset: offset.value,
        ...filtros.value
      }
    })
    const paramsSync = computed(() => {
      return {
        limit: limit.value,
        offset: offset.value,
        ...filtrosSync.value
      }
    })
    // Metodos
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      listarPac(params.value)
    }
    const onPageSyncPend = ({ first, page, pageCount, rows }) => {
      limitSyncPend.value = rows
      listarPacSyncPend({
        limit: limitSyncPend.value,
        offset: offsetSyncPend.value
      })
    }
    const onPageSync = ({ first, page, pageCount, rows }) => {
      limitSync.value = rows
      listarPacSync({
        limit: limitSync.value,
        offset: offsetSync.value
      })
    }
    const listarPac = () => {
      const object = {
        ...params.value,
        limit: limit.value,
        offset: offset.value
      }
      PacientesService.value.paginate(object).then(({ data }) => {
        pacientes.value = data
      })
    }
    const listarPacSyncPend = () => {
      const object = {
        ...params.value,
        limit: limitSync.value,
        offset: offsetSync.value
      }
      PacientesService.value.listPacienteSyncPend(object).then(({ data }) => {
        pacientesSyncPend.value = data
        modalSyncPacPend.value = true
      })
    }
    const listarPacSync = () => {
      const object = {
        ...paramsSync.value,
        limit: limitSync.value,
        offset: offsetSync.value
      }
      PacientesService.value.listPacienteSync(object).then(({ data }) => {
        pacientesSync.value = data
        modalSyncPac.value = true
      })
    }
    const EditPac = (id) => {
      router.push({ name: 'pharmasan.ventas.configuracion.pacientes.editar', params: { id } })
    }
    const DeletePac = (id) => {
      Swal.fire({
          title: 'Esta seguro de eliminar este Paciente?',
          showDenyButton: true,
          denyButtonText: 'Cancelar',
          confirmButtonText: 'Eliminar'
        }).then((result) => {
          if (result.isConfirmed) {
            PacientesService.value.eliminarPac(id).then(({ data }) => {
              if (data.error) {
                console.error('Error al eliminar el Paciente, por favor intente nuevamente')
              } else {
                toast.add({ severity: 'success', summary: 'Exito', detail: 'Paciente eliminado exitosamente', life: 3000 })
              }
            })
          } else if (result.isDenied) {
            Swal.fire('Paciente no eliminado', '', 'info')
          }
        })
    }
    const limpiarFiltros = () => {
      filtros.value = {
        U_ACS_NmrIdentf: null,
        U_ACS_PrimerNmb: null
      }
      listarPac()
    }
    const SincronizarPac = () => {
      Swal.fire({
        title: 'Sincronizar Pacientes',
        text: '¿Está seguro de sincronizar los pacientes con SAP?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, enviar!'
      }).then((result) => {
        if (result.isConfirmed) {
          PacientesService.value.sync(pacientesSyncPend.value.rows).then((res) => {
            Swal.fire({
              icon: 'success',
              title: 'Sincronizar pacientes',
              text: 'Se enviaron a sincronizar exitosamente'
            }).then(() => {
              router.push({ name: 'pharmasan.ventas.configuracion.pacientes' })
            })
          }).then(() => {
            listarPacSyncPend()
          })
        }
        // else {
        //   toast.add({ severity: 'info', summary: 'Informacion', detail: 'Se canceló la acción', life: 3000 })
        // }
      })
    }
    onMounted(() => {
      listarPac()
    })
</script>
<style>
  .p-dropdown-label{
    font-size: 0.75rem !important
  }
  .tipoId .p-dropdown-trigger {
    display: none !important;
  }
  ::-webkit-scrollbar {
    width: 0.2rem;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
</style>
